import * as Types from "../typings/types.gen";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type GetAccountsQueryVariables = Types.Exact<{
  clientId: Types.Scalars["uuid"];
  search?: Types.InputMaybe<Types.Scalars["String"]>;
  limit?: Types.InputMaybe<Types.Scalars["Int"]>;
  offset?: Types.InputMaybe<Types.Scalars["Int"]>;
  orderBy?: Types.InputMaybe<
    Array<Types.ClientAccounts_Order_By> | Types.ClientAccounts_Order_By
  >;
}>;

export type GetAccountsQuery = {
  __typename?: "query_root";
  clientAccounts: Array<{
    __typename?: "clientAccounts";
    id: any;
    name: string;
    metadata: any;
  }>;
  clientAccountsAgg: {
    __typename?: "clientAccounts_aggregate";
    aggregate?: {
      __typename?: "clientAccounts_aggregate_fields";
      count: number;
    } | null;
  };
};

export const GetAccountsDocument = gql`
  query getAccounts(
    $clientId: uuid!
    $search: String = "%%"
    $limit: Int = 10
    $offset: Int = 0
    $orderBy: [clientAccounts_order_by!] = [{ name: asc }]
  ) {
    clientAccounts(
      where: { clientId: { _eq: $clientId }, name: { _ilike: $search } }
      limit: $limit
      offset: $offset
      order_by: $orderBy
    ) {
      id
      name
      metadata
    }
    clientAccountsAgg(
      where: { clientId: { _eq: $clientId }, name: { _ilike: $search } }
    ) {
      aggregate {
        count
      }
    }
  }
`;

/**
 * __useGetAccountsQuery__
 *
 * To run a query within a React component, call `useGetAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountsQuery({
 *   variables: {
 *      clientId: // value for 'clientId'
 *      search: // value for 'search'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useGetAccountsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAccountsQuery,
    GetAccountsQueryVariables
  > &
    (
      | { variables: GetAccountsQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAccountsQuery, GetAccountsQueryVariables>(
    GetAccountsDocument,
    options,
  );
}
export function useGetAccountsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAccountsQuery,
    GetAccountsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAccountsQuery, GetAccountsQueryVariables>(
    GetAccountsDocument,
    options,
  );
}
export function useGetAccountsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetAccountsQuery,
    GetAccountsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetAccountsQuery, GetAccountsQueryVariables>(
    GetAccountsDocument,
    options,
  );
}
export type GetAccountsQueryHookResult = ReturnType<typeof useGetAccountsQuery>;
export type GetAccountsLazyQueryHookResult = ReturnType<
  typeof useGetAccountsLazyQuery
>;
export type GetAccountsSuspenseQueryHookResult = ReturnType<
  typeof useGetAccountsSuspenseQuery
>;
export type GetAccountsQueryResult = Apollo.QueryResult<
  GetAccountsQuery,
  GetAccountsQueryVariables
>;
