import { Select, Typography } from "antd";
import styles from "./PortalConfiguration.module.scss";
import { FeedTblCol } from "shared/types/assetExporter";

interface Option {
  id: string;
  name: string;
}

interface PortalConfigurationSelectProps {
  label: string;
  optional?: boolean;
  loading: boolean;
  options?: Option[];
  value: string | undefined;
  onChange: (value: string | undefined) => void;
  onColumnChange: (value: string) => void;
  feedColumns: FeedTblCol[];
}

const PortalColumnSelect = ({
  feedColumns,
  isVisible,
  onChange,
}: {
  feedColumns: FeedTblCol[];
  isVisible: boolean;
  onChange: (value: string) => void;
}) => {
  const feedColumnOptions = feedColumns.map(column => ({
    label: column.title,
    value: column.key,
  }));

  if (!isVisible) return null;
  return (
    <div className={styles.columnSelectWrapper}>
      <div>
        <Typography.Text>Feed Column</Typography.Text>
      </div>
      <Select
        className={styles.select}
        onChange={value => onChange(String(value))}
      >
        {feedColumnOptions.map(feedColumn => {
          return (
            <Select.Option key={feedColumn.value} value={feedColumn.value}>
              {feedColumn.label}
            </Select.Option>
          );
        })}
      </Select>
    </div>
  );
};

export const PortalConfigurationSelect: React.FC<
  PortalConfigurationSelectProps
> = ({
  label,
  optional,
  loading,
  options,
  value,
  onChange,
  onColumnChange,
  feedColumns,
}) => {
  return (
    <div>
      <div>
        <Typography.Text>{label}</Typography.Text>
        {optional && (
          <Typography.Text type="secondary"> (optional)</Typography.Text>
        )}
      </div>
      <div className={styles.selectContainer}>
        <Select
          loading={loading}
          className={styles.select}
          onChange={onChange}
          allowClear
        >
          <Select.Option value="">Feed Column</Select.Option>
          {options && options.length > 0 && (
            <Select.OptGroup label={label}>
              {options.map(option => (
                <Select.Option key={option.id} value={option.id}>
                  {option.name}
                </Select.Option>
              ))}
            </Select.OptGroup>
          )}
        </Select>
        <PortalColumnSelect
          feedColumns={feedColumns}
          isVisible={value === ""}
          onChange={onColumnChange}
        />
      </div>
    </div>
  );
};
