import * as Types from "../typings/types.gen";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type ClientDetailFragment = {
  __typename?: "orgClients";
  id: any;
  name: string;
  slug?: string | null;
  vertical?: Types.Vertical_Enum | null;
  createdAt: any;
  orgId?: string | null;
  updatedAt: any;
};

export type GetClientDetailQueryVariables = Types.Exact<{
  clientId: Types.Scalars["uuid"];
}>;

export type GetClientDetailQuery = {
  __typename?: "query_root";
  orgClient?: {
    __typename?: "orgClients";
    id: any;
    name: string;
    slug?: string | null;
    vertical?: Types.Vertical_Enum | null;
    createdAt: any;
    orgId?: string | null;
    updatedAt: any;
  } | null;
};

export type GetClientByNameQueryVariables = Types.Exact<{
  name: Types.Scalars["String"];
}>;

export type GetClientByNameQuery = {
  __typename?: "query_root";
  orgClients: Array<{ __typename?: "orgClients"; id: any; name: string }>;
};

export type GetClientsQueryVariables = Types.Exact<{
  limit?: Types.InputMaybe<Types.Scalars["Int"]>;
  offset?: Types.InputMaybe<Types.Scalars["Int"]>;
  orderBy?: Types.InputMaybe<
    Array<Types.OrgClients_Order_By> | Types.OrgClients_Order_By
  >;
  search?: Types.InputMaybe<Types.Scalars["String"]>;
}>;

export type GetClientsQuery = {
  __typename?: "query_root";
  orgClients: Array<{
    __typename?: "orgClients";
    id: any;
    name: string;
    slug?: string | null;
    vertical?: Types.Vertical_Enum | null;
    createdAt: any;
    orgId?: string | null;
    updatedAt: any;
  }>;
  orgClientsAgg: {
    __typename?: "orgClients_aggregate";
    aggregate?: {
      __typename?: "orgClients_aggregate_fields";
      count: number;
    } | null;
  };
};

export type GetClientsByOrgIdQueryVariables = Types.Exact<{
  limit?: Types.InputMaybe<Types.Scalars["Int"]>;
  offset?: Types.InputMaybe<Types.Scalars["Int"]>;
  orderBy?: Types.InputMaybe<
    Array<Types.OrgClients_Order_By> | Types.OrgClients_Order_By
  >;
  orgId: Types.Scalars["String"];
  search?: Types.InputMaybe<Types.Scalars["String"]>;
}>;

export type GetClientsByOrgIdQuery = {
  __typename?: "query_root";
  orgClients: Array<{
    __typename?: "orgClients";
    id: any;
    name: string;
    slug?: string | null;
    vertical?: Types.Vertical_Enum | null;
    createdAt: any;
    orgId?: string | null;
    updatedAt: any;
  }>;
};

export const ClientDetailFragmentDoc = gql`
  fragment ClientDetail on orgClients {
    id
    name
    slug
    vertical
    createdAt
    orgId
    updatedAt
  }
`;
export const GetClientDetailDocument = gql`
  query getClientDetail($clientId: uuid!) {
    orgClient(id: $clientId) {
      ...ClientDetail
    }
  }
  ${ClientDetailFragmentDoc}
`;

/**
 * __useGetClientDetailQuery__
 *
 * To run a query within a React component, call `useGetClientDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientDetailQuery({
 *   variables: {
 *      clientId: // value for 'clientId'
 *   },
 * });
 */
export function useGetClientDetailQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetClientDetailQuery,
    GetClientDetailQueryVariables
  > &
    (
      | { variables: GetClientDetailQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetClientDetailQuery, GetClientDetailQueryVariables>(
    GetClientDetailDocument,
    options,
  );
}
export function useGetClientDetailLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClientDetailQuery,
    GetClientDetailQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetClientDetailQuery,
    GetClientDetailQueryVariables
  >(GetClientDetailDocument, options);
}
export function useGetClientDetailSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetClientDetailQuery,
    GetClientDetailQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetClientDetailQuery,
    GetClientDetailQueryVariables
  >(GetClientDetailDocument, options);
}
export type GetClientDetailQueryHookResult = ReturnType<
  typeof useGetClientDetailQuery
>;
export type GetClientDetailLazyQueryHookResult = ReturnType<
  typeof useGetClientDetailLazyQuery
>;
export type GetClientDetailSuspenseQueryHookResult = ReturnType<
  typeof useGetClientDetailSuspenseQuery
>;
export type GetClientDetailQueryResult = Apollo.QueryResult<
  GetClientDetailQuery,
  GetClientDetailQueryVariables
>;
export const GetClientByNameDocument = gql`
  query getClientByName($name: String!) {
    orgClients(where: { name: { _ilike: $name } }) {
      id
      name
    }
  }
`;

/**
 * __useGetClientByNameQuery__
 *
 * To run a query within a React component, call `useGetClientByNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientByNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientByNameQuery({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useGetClientByNameQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetClientByNameQuery,
    GetClientByNameQueryVariables
  > &
    (
      | { variables: GetClientByNameQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetClientByNameQuery, GetClientByNameQueryVariables>(
    GetClientByNameDocument,
    options,
  );
}
export function useGetClientByNameLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClientByNameQuery,
    GetClientByNameQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetClientByNameQuery,
    GetClientByNameQueryVariables
  >(GetClientByNameDocument, options);
}
export function useGetClientByNameSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetClientByNameQuery,
    GetClientByNameQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetClientByNameQuery,
    GetClientByNameQueryVariables
  >(GetClientByNameDocument, options);
}
export type GetClientByNameQueryHookResult = ReturnType<
  typeof useGetClientByNameQuery
>;
export type GetClientByNameLazyQueryHookResult = ReturnType<
  typeof useGetClientByNameLazyQuery
>;
export type GetClientByNameSuspenseQueryHookResult = ReturnType<
  typeof useGetClientByNameSuspenseQuery
>;
export type GetClientByNameQueryResult = Apollo.QueryResult<
  GetClientByNameQuery,
  GetClientByNameQueryVariables
>;
export const GetClientsDocument = gql`
  query getClients(
    $limit: Int = 10
    $offset: Int = 0
    $orderBy: [orgClients_order_by!] = [{ name: asc }]
    $search: String = "%%"
  ) {
    orgClients(
      where: { name: { _ilike: $search } }
      limit: $limit
      offset: $offset
      order_by: $orderBy
    ) {
      ...ClientDetail
    }
    orgClientsAgg(where: { name: { _ilike: $search } }) {
      aggregate {
        count
      }
    }
  }
  ${ClientDetailFragmentDoc}
`;

/**
 * __useGetClientsQuery__
 *
 * To run a query within a React component, call `useGetClientsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      orderBy: // value for 'orderBy'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useGetClientsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetClientsQuery,
    GetClientsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetClientsQuery, GetClientsQueryVariables>(
    GetClientsDocument,
    options,
  );
}
export function useGetClientsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClientsQuery,
    GetClientsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetClientsQuery, GetClientsQueryVariables>(
    GetClientsDocument,
    options,
  );
}
export function useGetClientsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetClientsQuery,
    GetClientsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetClientsQuery, GetClientsQueryVariables>(
    GetClientsDocument,
    options,
  );
}
export type GetClientsQueryHookResult = ReturnType<typeof useGetClientsQuery>;
export type GetClientsLazyQueryHookResult = ReturnType<
  typeof useGetClientsLazyQuery
>;
export type GetClientsSuspenseQueryHookResult = ReturnType<
  typeof useGetClientsSuspenseQuery
>;
export type GetClientsQueryResult = Apollo.QueryResult<
  GetClientsQuery,
  GetClientsQueryVariables
>;
export const GetClientsByOrgIdDocument = gql`
  query getClientsByOrgId(
    $limit: Int = 10
    $offset: Int = 0
    $orderBy: [orgClients_order_by!] = { name: asc }
    $orgId: String!
    $search: String = "%%"
  ) {
    orgClients(
      limit: $limit
      offset: $offset
      order_by: $orderBy
      where: { orgId: { _eq: $orgId }, name: { _ilike: $search } }
    ) {
      ...ClientDetail
    }
  }
  ${ClientDetailFragmentDoc}
`;

/**
 * __useGetClientsByOrgIdQuery__
 *
 * To run a query within a React component, call `useGetClientsByOrgIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientsByOrgIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientsByOrgIdQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      orderBy: // value for 'orderBy'
 *      orgId: // value for 'orgId'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useGetClientsByOrgIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetClientsByOrgIdQuery,
    GetClientsByOrgIdQueryVariables
  > &
    (
      | { variables: GetClientsByOrgIdQueryVariables; skip?: boolean }
      | { skip: boolean }
    ),
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetClientsByOrgIdQuery,
    GetClientsByOrgIdQueryVariables
  >(GetClientsByOrgIdDocument, options);
}
export function useGetClientsByOrgIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetClientsByOrgIdQuery,
    GetClientsByOrgIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetClientsByOrgIdQuery,
    GetClientsByOrgIdQueryVariables
  >(GetClientsByOrgIdDocument, options);
}
export function useGetClientsByOrgIdSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetClientsByOrgIdQuery,
    GetClientsByOrgIdQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetClientsByOrgIdQuery,
    GetClientsByOrgIdQueryVariables
  >(GetClientsByOrgIdDocument, options);
}
export type GetClientsByOrgIdQueryHookResult = ReturnType<
  typeof useGetClientsByOrgIdQuery
>;
export type GetClientsByOrgIdLazyQueryHookResult = ReturnType<
  typeof useGetClientsByOrgIdLazyQuery
>;
export type GetClientsByOrgIdSuspenseQueryHookResult = ReturnType<
  typeof useGetClientsByOrgIdSuspenseQuery
>;
export type GetClientsByOrgIdQueryResult = Apollo.QueryResult<
  GetClientsByOrgIdQuery,
  GetClientsByOrgIdQueryVariables
>;
