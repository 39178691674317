import React, { useContext, useState } from "react";

interface PortalExportContextType {
  selectedBrandId: string | undefined;
  selectedBrandColumn: string;
  selectedAccountId: string | undefined;
  selectedAccountColumn: string;
  selectedColumns: string[];
  setSelectedBrandId: React.Dispatch<React.SetStateAction<string | undefined>>;
  setSelectedBrandColumn: React.Dispatch<React.SetStateAction<string>>;
  setSelectedAccountId: React.Dispatch<
    React.SetStateAction<string | undefined>
  >;
  setSelectedAccountColumn: React.Dispatch<React.SetStateAction<string>>;
  setSelectedColumns: React.Dispatch<React.SetStateAction<string[]>>;
}

const PortalExportContext = React.createContext<
  PortalExportContextType | undefined
>(undefined);

export const PortalExportProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [selectedBrandId, setSelectedBrandId] = useState<string | undefined>();
  const [selectedBrandColumn, setSelectedBrandColumn] = useState<string>("");
  const [selectedAccountId, setSelectedAccountId] = useState<
    string | undefined
  >();
  const [selectedAccountColumn, setSelectedAccountColumn] =
    useState<string>("");
  const [selectedColumns, setSelectedColumns] = useState<string[]>([]);

  const contextValue: PortalExportContextType = {
    selectedBrandId,
    selectedBrandColumn,
    selectedAccountId,
    selectedAccountColumn,
    selectedColumns,
    setSelectedBrandId,
    setSelectedBrandColumn,
    setSelectedAccountId,
    setSelectedAccountColumn,
    setSelectedColumns,
  };

  return (
    <PortalExportContext.Provider value={contextValue}>
      {children}
    </PortalExportContext.Provider>
  );
};

PortalExportProvider.displayName = "PortalExportProvider";

export const usePortalExportContext = () => {
  const portalExportContext = useContext(PortalExportContext);
  if (!portalExportContext) {
    throw new Error(
      "usePortalExportContext must be used within a PortalExportProvider",
    );
  }
  return portalExportContext;
};
