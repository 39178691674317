import { Checkbox, Table } from "antd";
import styles from "./PortalConfiguration.module.scss";
import { useFeedContext } from "../shared/contexts/FeedContext";
import { PortalConfigurationTableData, RowType } from "./types";
import { getPortalConfigurationTableData } from "./utils/getPortalConfigurationTableData";
import { usePortalExportContext } from "./PortalExportContext";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import { getSelectedPortalColumns } from "./utils/getSelectedPortalColumns";

export const PortalConfigurationTable = () => {
  const { selectedColumns, setSelectedColumns } = usePortalExportContext();
  const { columns: feedColumns } = useFeedContext();

  const handleCheckboxChange = (
    event: CheckboxChangeEvent,
    rowData: PortalConfigurationTableData,
  ) => {
    const updatedColumns = getSelectedPortalColumns(
      selectedColumns,
      rowData.title,
      event.target.checked,
    );
    setSelectedColumns(updatedColumns);
  };

  const columns = [
    {
      title: "Other feed columns to be also added as fields",
      render: (_: string, rowData: PortalConfigurationTableData) => (
        <Checkbox
          className={styles.checkbox}
          disabled={rowData.rowType === RowType.Media}
          checked={selectedColumns.includes(rowData.title)}
          onChange={event => handleCheckboxChange(event, rowData)}
        >
          <span className={styles.checkboxLabel}>{rowData.title}</span>
        </Checkbox>
      ),
    },
  ];

  const tableData = getPortalConfigurationTableData(feedColumns);

  return (
    <Table
      className={styles.table}
      columns={columns}
      dataSource={tableData}
      pagination={false}
      bordered
      size="small"
    />
  );
};
