import { Typography } from "antd";
import { useGetBrandsQuery } from "../../../../graphql/brands.gen";
import { useGetAccountsQuery } from "graphql/accounts.gen";
import { useGetClientsByOrgIdQuery } from "graphql/clients.gen";
import { usePortalExportContext } from "./PortalExportContext";
import { useFeedContext } from "../shared/contexts/FeedContext";
import { PortalConfigurationSelect } from "./PortalConfigurationSelect";

const orgId = process.env.REACT_APP_AUTH_ORGANIZATION;

export const PortalConfigurationFields = () => {
  const {
    setSelectedBrandId,
    setSelectedBrandColumn,
    setSelectedAccountId,
    setSelectedAccountColumn,
    selectedBrandId,
    selectedAccountId,
  } = usePortalExportContext();
  const { columns: feedColumns } = useFeedContext();

  const { data: clientsData } = useGetClientsByOrgIdQuery({
    variables: { orgId },
  });
  const clientId = clientsData?.orgClients[0]?.id;

  const { data: brandsData, loading: brandsLoading } = useGetBrandsQuery({
    variables: { clientId, limit: null },
  });
  const { data: accountsData, loading: accountsLoading } = useGetAccountsQuery({
    variables: { clientId, limit: null },
  });

  return (
    <>
      <Typography.Text strong>
        Add tags to the assets to find them in the portal using filters
      </Typography.Text>

      <PortalConfigurationSelect
        label="Brands"
        optional
        loading={brandsLoading}
        options={brandsData?.brands}
        value={selectedBrandId}
        onChange={setSelectedBrandId}
        onColumnChange={setSelectedBrandColumn}
        feedColumns={feedColumns}
      />

      <PortalConfigurationSelect
        label="Accounts"
        loading={accountsLoading}
        options={accountsData?.clientAccounts}
        value={selectedAccountId}
        onChange={setSelectedAccountId}
        onColumnChange={setSelectedAccountColumn}
        feedColumns={feedColumns}
      />
    </>
  );
};
