import * as Types from "../typings/types.gen";

import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
const defaultOptions = {} as const;
export type GetBrandsQueryVariables = Types.Exact<{
  clientId?: Types.InputMaybe<Types.Scalars["uuid"]>;
  limit?: Types.InputMaybe<Types.Scalars["Int"]>;
  offset?: Types.InputMaybe<Types.Scalars["Int"]>;
  orderBy?: Types.InputMaybe<
    Array<Types.Brands_Order_By> | Types.Brands_Order_By
  >;
  search?: Types.InputMaybe<Types.Scalars["String"]>;
}>;

export type GetBrandsQuery = {
  __typename?: "query_root";
  brands: Array<{
    __typename?: "brands";
    id: any;
    name: string;
    metadata: any;
  }>;
  brandsAgg: {
    __typename?: "brands_aggregate";
    aggregate?: {
      __typename?: "brands_aggregate_fields";
      count: number;
    } | null;
  };
};

export const GetBrandsDocument = gql`
  query getBrands(
    $clientId: uuid = null
    $limit: Int = 10
    $offset: Int = 0
    $orderBy: [brands_order_by!] = [{ name: asc }]
    $search: String = "%%"
  ) {
    brands(
      where: {
        _or: [
          { ownerClientId: { _eq: $clientId } }
          { ownerClientId: { _is_null: true } }
        ]
        name: { _ilike: $search }
      }
      limit: $limit
      offset: $offset
      order_by: $orderBy
    ) {
      id
      name
      metadata
    }
    brandsAgg(
      where: {
        _or: [
          { ownerClientId: { _eq: $clientId } }
          { ownerClientId: { _is_null: true } }
        ]
        name: { _ilike: $search }
      }
    ) {
      aggregate {
        count
      }
    }
  }
`;

/**
 * __useGetBrandsQuery__
 *
 * To run a query within a React component, call `useGetBrandsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBrandsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBrandsQuery({
 *   variables: {
 *      clientId: // value for 'clientId'
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      orderBy: // value for 'orderBy'
 *      search: // value for 'search'
 *   },
 * });
 */
export function useGetBrandsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetBrandsQuery,
    GetBrandsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetBrandsQuery, GetBrandsQueryVariables>(
    GetBrandsDocument,
    options,
  );
}
export function useGetBrandsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetBrandsQuery,
    GetBrandsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetBrandsQuery, GetBrandsQueryVariables>(
    GetBrandsDocument,
    options,
  );
}
export function useGetBrandsSuspenseQuery(
  baseOptions?: Apollo.SuspenseQueryHookOptions<
    GetBrandsQuery,
    GetBrandsQueryVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetBrandsQuery, GetBrandsQueryVariables>(
    GetBrandsDocument,
    options,
  );
}
export type GetBrandsQueryHookResult = ReturnType<typeof useGetBrandsQuery>;
export type GetBrandsLazyQueryHookResult = ReturnType<
  typeof useGetBrandsLazyQuery
>;
export type GetBrandsSuspenseQueryHookResult = ReturnType<
  typeof useGetBrandsSuspenseQuery
>;
export type GetBrandsQueryResult = Apollo.QueryResult<
  GetBrandsQuery,
  GetBrandsQueryVariables
>;
